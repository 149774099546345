import React from 'react'
import { BlueBreaker } from '../content/BlueBreaker/BlueBreaker';
import ContactForm from '../content/ContactForm/ContactForm';
import HowToJoin from '../content/HowToJoin/HowToJoin';
import InfoCards from '../content/InfoCards/InfoCards';
import WelcomeBanner from '../content/WelcomeBanner/WelcomeBanner';
import './../../App.css';

export const HomeScreen = () => {
    return (
        <>
            <div id="pageTop">
            <WelcomeBanner />
            </div>
            <InfoCards />
            <BlueBreaker />
            <HowToJoin />
            <div id="section1">
            <ContactForm />
            </div>
        </>
    )
}
