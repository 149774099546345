import React from 'react';

const contactEmail = ({name, email, subject, message}) => {

    return (

        <div style={{padding: '3rem'}}>
            <div style={{maxWidth: '80rem', margin: '0 auto', paddingLeft: '1.5rem', paddingRight: '1.5rem'}}>
                <div style={{
                    backgroundColor: '#ffffff',
                    overflow: 'hidden',
                    boxShadow: '0 0 1.25rem rgba(0, 0, 0, 0.1)',
                    borderRadius: '0.5rem'
                }}>
                    <div style={{
                        padding: '1.5rem',
                        paddingLeft: '5rem',
                        paddingRight: '5rem',
                        backgroundColor: '#ffffff',
                        borderBottom: '1px solid #e2e8f0'
                    }}>
                        <div>
                            <img src="https://despachozwolf.com/static/media/despacho_zwolf_logo_large.8341716a.png"
                                 alt="Despacho Zwolf"
                                style={{width: '20rem', height: 'auto', display: 'block', margin: '2rem auto'}}
                            />
                        </div>
                        <div style={{fontSize: '1.5rem'}}>
                            <p>
                            Estimado(a) Administrador(a), se ha recibido un mensaje de contacto desde la página web
                                de Despacho Zwolf.
                            </p>
                            <br/>
                        </div>
                        <div style={{marginTop: '1.5rem', color: '#6b7280'}}>
                            <p>Por favor, revise la información proporcionada por el usuario:</p>
                            <br/>
                            <p>Nombre: <strong>{name}</strong></p>
                            <p>Email: <strong>{email}</strong></p>
                            <p>Asunto: <strong>{subject}</strong></p>
                            <p>Mensaje: <strong>{message}</strong></p>
                            <br/>
                            <p>Atentamente,</p>
                            <strong>Despacho Zwolf.</strong>
                        </div>
                    </div>
                    <div style={{padding: '1rem'}}>
                        <p style={{fontSize: '0.75rem', color: '#9ca3af'}}>
                            Este email electrónico, así como los archivos adjuntos en su caso, contienen información
                            confidencial propiedad de Grupo Zwolf SAPI de CV de conformidad con las leyes aplicables en
                            la República Mexicana, y esta dirigido únicamente al (a los) destinatario (s) señalados en
                            el mismo. Si usted recibe esta comunicación y no es el destinatario de la misma, le
                            informamos que no se encuentra autorizado para leer o hacer uso de ella. Agradeceremos
                            reportar este error al remitente del email, eliminar el mensaje de su computadora y en su
                            caso, los archivos adjuntos al mismo de forma inmediata. Cualquier revisión, retransmisión,
                            divulgación, difusión o cualquier otro uso de este email, queda expresamente prohibida y
                            sujeta a las sanciones establecidas en las leyes correspondientes. Todas las opiniones
                            contenidas en este email electrónico son propias del autor del mensaje y no necesariamente
                            coinciden con las de Grupo Zwolf SAPI de CV. Este email electrónico no pretende, ni debe ser
                            considerado como constitutivo de ninguna relación legal, contractual o de otra índole
                            similar. Confidentiality Notice. This e-mail and the attached files, if any, might contain
                            confidential information property of Grupo Zwolf SAPI de CV in accordance with the
                            applicable laws of Mexico. If you receive this communication and you are not the intended
                            addressee of such, we inform you that you are not authorized to read or by any instance,
                            make use of such information. We would appreciate, if you please report this error to the
                            sender and delete it from your computer as well as the relevant files attached immediately.
                            Any review, re-transmission, disclosure, dissemination or any other use of this mail is
                            strictly prohibited. Any views or opinions presented in this email are solely those of the
                            author and do not necessarily represent those of Grupo Zwolf SAPI de CV. No employee is
                            authorized to conclude any binding agreement on behalf of Grupo Zwolf SAPI de CV by email.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default contactEmail;
