import React from 'react'
// import { PreciosScreen } from './PreciosScreen'

export const FAQScreen = () => {
    return (
        <div className="container fs-5">
            <div className="row text-left py-5">
                <div className="col">
                    <h1 className="display-4">
                        Preguntas Frecuentes
                    </h1>
                </div>
            </div>
            <div className="row text-left py-5">
                <div className="col">
                    <div className="accordion accordion-flush" id="accordionExample">

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button collapsed fs-3 " type="button"
                                        data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false"
                                        aria-controls="collapseOne">
                                    ¿Cómo me puedo comunicar a Despacho Zwolf?
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne"
                                 data-bs-parent="#accordionExample">
                                <div className="accordion-body text-start">
                                    <p>
                                        Tenemos diferentes medios de contacto:
                                    </p>
                                    <ul className="pb-3">
                                        <li>Puedes comunicarte por Whatsapp o vía telefónica al: +52 1 33 25 44 63 31
                                        </li>
                                        <li>Enviar un correo a: despachozwolf@gmail.com</li>
                                        <li> Formulario de contacto en la página web: www.despachozwolf.com</li>
                                    </ul>
                                    <p>
                                        Y en breve, nuestro departamento de Atención al cliente se comunicará contigo
                                        para explicarte a detalle el proceso y resolver cualquier duda que tengas.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button collapsed fs-3 " type="button"
                                        data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false"
                                        aria-controls="collapseTwo">
                                    ¿Cómo me registro en Despacho Zwolf?
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo"
                                 data-bs-parent="#accordionExample">
                                <div className="accordion-body text-start">
                                    <p>
                                        Accede a <a href="www.despachozwolf.com">despachozwolf</a> , después da click en
                                        el botón “Regístrate” que se encuentra en el menú superior, completa tu datos y
                                        ¡Listo! Ya tendremos tu información para contactarte y comenzar a presentar tus
                                        declaraciones.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button collapsed fs-3 " type="button"
                                        data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false"
                                        aria-controls="collapseThree">
                                    ¿Cómo sé si tengo Declaraciones pendientes de presentar?
                                </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse"
                                 aria-labelledby="headingThree"
                                 data-bs-parent="#accordionExample">
                                <div className="accordion-body text-start">
                                    <p>
                                        Es muy sencillo, solo necesitaremos tus datos de acceso al SAT para poder
                                        ingresar a tu portal y ahí mismo revisaremos tu status para saber si todo está
                                        en orden o hay algo pendiente de presentar. <br/>
                                        Para que puedas comprobar esa información, te haremos llegar tu Opinión de
                                        Cumplimiento actualizada.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFour">
                                <button className="accordion-button collapsed fs-3 " type="button"
                                        data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false"
                                        aria-controls="collapseFour">
                                    ¿Cuáles son los datos de acceso al SAT?
                                </button>
                            </h2>
                            <div id="collapseFour" className="accordion-collapse collapse"
                                 aria-labelledby="headingFour"
                                 data-bs-parent="#accordionExample">
                                <div className="accordion-body text-start">
                                    <p>
                                        Son RFC, clave CIEC, EFIRMA y contraseña de la EFIRMA.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFive">
                                <button className="accordion-button collapsed fs-3 " type="button"
                                        data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false"
                                        aria-controls="collapseFive">
                                    ¿Qué precio tiene la Asesoría?
                                </button>
                            </h2>
                            <div id="collapseFive" className="accordion-collapse collapse"
                                 aria-labelledby="headingFive"
                                 data-bs-parent="#accordionExample">
                                <div className="accordion-body text-start">
                                    <p>
                                        Si ya eres cliente de Despacho Zwolf, podrás solicitar ayuda y Asesoría siempre
                                        que lo necesites, sin costo extra. <br/>
                                        Y para personas interesadas en ser clientes de Despacho Zwolf, la primer
                                        Asesoría es GRATIS.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingSix">
                                <button className="accordion-button collapsed fs-3 " type="button"
                                        data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false"
                                        aria-controls="collapseSix">
                                    ¿Qué precio tienen sus Servicios Contables?
                                </button>
                            </h2>
                            <div id="collapseSix" className="accordion-collapse collapse"
                                 aria-labelledby="headingSix"
                                 data-bs-parent="#accordionExample">
                                <div className="accordion-body text-start">
                                    <p>
                                        Nuestros precios los manejamos según las pólizas que tengas durante el mes, es
                                        decir,la cantidad de facturas emitidas y recibidas que tengas durante el mes y
                                        en dado caso, nóminas timbradas
                                        En este link puedes conocer el tabulador de precios mensuales, para personas
                                        físicas o morales SIN empleados:
                                        <a href="https://zwolf.store/producto/contabilidad-y-declaraciones-sat/">Lista
                                            de precios</a>
                                        <br/>
                                        En este link puedes conocer el tabulador de precios mensuales, para personas
                                        físicas o morales CON empleados:
                                        <a href="https://zwolf.store/producto/contabilidad-declaraciones-sat-imss-e-impuestos-sobre-nomina/">Lista
                                            de precios</a>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingSeven">
                                <button className="accordion-button collapsed fs-3 " type="button"
                                        data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false"
                                        aria-controls="collapseSeven">
                                    ¿Puedo emitir facturas para mis clientes en el Sistema de Grupo Zwolf?
                                </button>
                            </h2>
                            <div id="collapseSeven" className="accordion-collapse collapse"
                                 aria-labelledby="headingSeven"
                                 data-bs-parent="#accordionExample">
                                <div className="accordion-body text-start">
                                    <p>
                                        Sí, cualquier persona puede utilizar nuestro sistema de facturación.
                                        Factura y timbra nómina desde nuestra plataforma sincronizada con el SAT, accede
                                        a tus movimientos, obtén reportes y almacena tus catálogos de productos y
                                        clientes.
                                        Puedes adquirir tus paquetes de facturación dando clic aquí
                                        Click aquí

                                        Una vez realizado tu pago envíanos el comprobante a ventas@grupozwolf.com para
                                        activar tu usuario.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingEight">
                                <button className="accordion-button collapsed fs-3 " type="button"
                                        data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false"
                                        aria-controls="collapseEight">
                                    ¿Despacho Zwolf me puede ayudar a solicitar las facturas de mis gastos?
                                </button>
                            </h2>
                            <div id="collapseEight" className="accordion-collapse collapse"
                                 aria-labelledby="headingEight"
                                 data-bs-parent="#accordionExample">
                                <div className="accordion-body text-start">
                                    <p>
                                        ¡Es correcto! Nosotros nos encargamos para que no te preocupes por eso. Solo
                                        tienes que enviar la foto de tu ticket, un correo y tu Constancia Fiscal a
                                        nuestro Whatsapp +52 1 33 25 44 63 31 o al correo despachozwolf@gmail.com. Si ya
                                        eres cliente de Despacho Zwolf, solo necesitaremos que envíes la foto de tu
                                        ticket de compra.
                                        <br/>
                                        Cada factura tiene un costo de <strong>$20.00 MXN.</strong>
                                    </p>
                                </div>
                            </div>
                        </div>


                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingNine">
                                <button className="accordion-button collapsed fs-3 " type="button"
                                        data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false"
                                        aria-controls="collapseNine">
                                    ¿Cómo se realizan los pagos con 3DSecure?
                                </button>
                            </h2>
                            <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingNine"
                                 data-bs-parent="#accordionExample">
                                <div className="accordion-body text-start">
                                    <p>
                                        Después de llenar el formulario de pago, y dar click en el botón de "Realizar
                                        pago", El banco emisor de tu tarjeta de crédito te pedirá la "clave de pago
                                        seguro (3D Secure)" para autenticación de tu tarjeta. Esto quiere decir que el
                                        banco quiere saber que eres realmente el dueño de tu tarjeta. Cada banco tiene
                                        un proceso diferente para dar de alta la clave de pago seguro por internet (3D
                                        Secure):
                                    </p>
                                    <ul className="pb-3">
                                        <li>Una clásica contraseña (puede cambiar).</li>
                                        <li>Una tarjeta de coordenadas que nuestro banco ha debido entregarnos (Se nos
                                            solicitará ingresar el número correspondiente a una columna y fila).
                                        </li>
                                        <li>Un sistema de dispositivo electrónico (se nos pedirá ingresar un código que
                                            cambia).
                                        </li>
                                        <li>Nuestra fecha de nacimiento.</li>
                                        <li>Otros.</li>
                                    </ul>
                                    <p>
                                        Lo que hace el servidor de tu banco es confirmar a la tienda en línea que eres
                                        el propietario de la tarjeta, a esto se le llama "Autenticación".
                                    </p>
                                    <p>
                                        Si no cuentas con tu "Clave de pago seguro (3D Secure)" solicítala vía
                                        telefónica a tu banco y realiza compras con la máxima seguridad.
                                    </p>

                                </div>
                            </div>
                        </div>


                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTen">
                                <button className="accordion-button collapsed fs-3" type="button"
                                        data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false"
                                        aria-controls="collapseTen">
                                    ¿En dónde puedo encontrar los acuses de las declaraciones presentadas?
                                </button>
                            </h2>
                            <div id="collapseTen" className="accordion-collapse collapse" aria-labelledby="headingTen"
                                 data-bs-parent="#accordionExample">
                                <div className="accordion-body text-start">
                                    Al ser cliente de Despacho Zwolf, tienes acceso a una carpeta compartida en donde
                                    encontrarás todos tus acuses y documentos relacionados con tus declaraciones. Ésta
                                    carpeta se encuentra sincronizada y protegida en la nube.
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
