import React from 'react'

export const PrivacidadScreen = () => {
    return (
        <div className="container py-5 fs-4">
            <div className="row text-start">
                <div className="col">
                    <h1 className="display-3">
                        Aviso de privacidad
                    </h1>
                    <div className="py-5">
                        <p className="h3">
                            ¿Quiénes Somos?
                        </p>
                        <p>
                            Grupo Zwolf SAPI de CV, mejor conocido como Grupo Zwolf, con domicilio en calle M Peña y Peña 2090 int 38, colonia Lomas del Paradero, ciudad Guadalajara, municipio o delegación Guadalajara, c.p. 44840, en la entidad de Jalisco, país México, y portal de internet https://despachozwolf.com, es el responsable del uso y protección de sus datos personales, y al respecto le informamos lo siguiente:
                        </p>
                    </div>

                    <div className="py-5">
                        <p className="h3">
                            ¿Para qué fines utilizaremos sus datos personales?
                        </p>
                        <p>
                            Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades que son necesarias para el servicio que solicita:
                        </p>
                        <ul>
                            <li>Servicios Contables</li>
                            <li>Servicios jurídicos</li>
                            <li>Servicios administrativos</li>
                            <li>Servicios de facturación electrónica</li>
                            <li>Servicios financieros</li>
                            <li>Servicios publicitarios</li>
                            <li>Funcionamiento y administración de nuestros negocios</li>
                            <li>Administración de los sitios web de Grupo Zwolf</li>
                            <li>Administración de los Servicios de Grupo Zwolf</li>
                            <li>Envio de Avisos</li>
                            <li>Solicitar actualización de datos y documentos de identificación</li>
                            <li>En general para hacer cumplir nuestros términos, condiciones y la operación</li>
                            <li>Cuando la ley o alguna autoridad lo requiera</li>
                        </ul>
                    </div>
                    <div className="py-5">
                        <p>
                            De manera adicional, utilizaremos su información personal para las siguientes finalidades secundarias que no son necesarias para el servicio solicitado, pero nos permiten y facilitan brindarle una mejor atención:
                        </p>
                        <ul>
                            <li>Desarrollo de nuevos productos y servicios</li>
                            <li>Encuestas de calidad del servicio o productos contratados y satisfacción del usuario</li>
                            <li>Mercadotecnia o publicitaria</li>
                            <li>Prospección comercial</li>
                        </ul>
                        <p>
                            En caso de que no desee que sus datos personales sean tratados para estos fines secundarios, desde este momento usted nos puede comunicar lo anterior a través del siguiente mecanismo:
                        </p>
                        <a href="mailto:info@despachozwolf.com?Subject=Solicitud%20de%derechos%20ARCO" className=" text-decoration-none user-select-all">
                            info@despachozwolf.com
                        </a>
                        <p>
                            <a href="https://www.infoem.org.mx/infoemIncluyentes/src/htm/comoPresentoDerechosArco.html">
                                Solicitud de derechos ARCO
                            </a>
                        </p>
                        <p>
                            La negativa para el uso de sus datos personales para estas finalidades no podrá ser motivo para que le neguemos los servicios y productos que solicita o contrata con nosotros.
                        </p>
                    </div>
                    <p className="h3">
                        ¿Dónde puedo consultar el aviso de privacidad integral?
                    </p>
                    <p>
                        Para conocer mayor información sobre los términos y condiciones en que serán tratados sus datos personales, como los terceros con quienes compartimos su información personal y la forma en que podrá ejercer sus derechos ARCO, puede consultar el aviso de privacidad integral en:
                    </p>
                    <p>
                        Portal de internet
                    </p>
                </div>
            </div>
        </div>
    )
}
