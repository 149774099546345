import React, { Component } from 'react'
import factura from './../../../img/icons/factura.svg'
import chart from './../../../img/icons/chart.svg'
import paper from './../../../img/icons/paper.svg'
import calendar from './../../../img/icons/calendar.svg'
import cloud from './../../../img/icons/cloud-computing.svg'
import chat from './../../../img/icons/chat.svg'


export default class InfoCards extends Component {

    iconStyle = {
        width: '5em',
        height: '5em'
    }



    render() {
        return (
            <div className="container pb-5 animate__animated animate__fadeIn fs-5">
                <div className="d-flex flex-row ">
                    <div className="col-sm-6 mt-5 d-none d-md-block">
                        <h2 className="display-3 text-start">
                            Nuestros Servicios 
                        </h2>
                    </div>
                    <div className="col-sm-6 mt-5 d-sm-block d-md-none">
                        <h2 className="h2 text-start">
                            Nuestros Servicios 
                        </h2>
                    </div>
                </div>
                <div className="d-flex flex-row">
                    <div className="col-sm-6 mt-2">
                        <p className="d-none d-sm-block d-md-block text-start pl-5">
                            Te ayudamos a cumplir con todo lo que las autoridades te solicitan
                        </p>
                        <p className="d-sm-none text-start  ">
                            Te ayudamos a cumplir con todo lo que las autoridades te solicitan
                        </p>
                    </div>
                </div>
                <div className="row ">
                    <div className="card-group pt-5">
                        <div className="card delete-border-from-card">
                            <img fetchpriority="low" className="card-img-top mx-auto icon-color-blue" src={ factura } alt="factura" style={this.iconStyle} />
                            <div className="card-body">
                                <h5 className="card-title text-uppercase p-4">Facturacion Electronica</h5>
                                <p className="card-text text-muted">Factura y timbra nómina desde nuestra plataforma sincronizada con el SAT, accede a tus movimientos, obtén reportes y almacena tus catálogos de productos y clientes.</p>
                            </div>
                        </div>
                        <div className="card delete-border-from-card">
                            <img fetchpriority="low" className="card-img-top mx-auto icon-color-blue" src={ chart } alt="Contabilidad" style={ this.iconStyle }/>
                            <div className="card-body">
                                <h5 className="card-title text-uppercase p-4">Contabilidad en linea</h5>
                                <p className="card-text text-muted">Tendrás un equipo en linea de expertos encargándose de todas tus necesidades contables.</p>
                            </div>
                        </div>
                        <div className="card delete-border-from-card">
                            <img fetchpriority="low" className="card-img-top mx-auto icon-color-blue" src={ paper } alt="Declaraciones" style={ this.iconStyle }/>
                            <div className="card-body">
                                <h5 className="card-title text-uppercase p-4">Declaraciones</h5>
                                <p className="card-text text-muted">Hacemos tu cálculo de impuestos y cuotas obrero patronales, además manejamos altas y bajas ante el IMSS, así como actualizaciones ante el SAT para matener tu informacion al día.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row ">
                    <div className="card-group pt-5">
                        <div className="card delete-border-from-card">
                            <img fetchpriority="low" className="card-img-top mx-auto icon-color-blue" src={ calendar } alt="factura" style={this.iconStyle} />
                            <div className="card-body">
                                <h5 className="card-title text-uppercase p-4">Regularizacion fiscal</h5>
                                <p className="card-text text-muted">Gestionamos un plan de acción para poner al día tu contabilidad.</p>
                            </div>
                        </div>
                        <div className="card delete-border-from-card">
                            <img fetchpriority="low" className="card-img-top mx-auto icon-color-blue" src={ cloud } alt="Contabilidad" style={ this.iconStyle }/>
                            <div className="card-body">
                                <h5 className="card-title text-uppercase p-4">Archivos en linea</h5>
                                <p className="card-text text-muted">Todos los archivos de tus declaraciones, acuses y respaldo de contabilidad estarán siempre a tu alcance sincronizados en la nube.</p>
                            </div>
                        </div>
                        <div className="card delete-border-from-card">
                            <img fetchpriority="low" className="card-img-top mx-auto icon-color-blue" src={ chat } alt="Declaraciones" style={ this.iconStyle }/>
                            <div className="card-body">
                                <h5 className="card-title text-uppercase p-4">Atencion personalizada</h5>
                                <p className="card-text text-muted">Puedes contactarnos desde la plataforma a través del chat, enviarnos correo, llamarnos o visitarnos en nuestras oficinas en donde siempre te atenderemos con gusto.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
