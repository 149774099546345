import Header from './components/partials/Header';
import WhatsApp from './components/WhatsApp';
import './App.css';
import { Footer } from './components/partials/Footer';
import { AppRouter } from './routes/AppRouter';

function App() {
  return (
    <>
    <div className="App">
      <Header />
        <AppRouter />
        
    </div>
    < Footer />
    </>
  );
}

export default App;
