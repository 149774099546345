import React, { Component } from 'react';
import number1 from './../../../img/icons/icons8-number-1-52.png'
import number2 from './../../../img/icons/icons8-number-2-52.png'
import number3 from './../../../img/icons/icons8-3-c-52.png'
import star from './../../../img/icons/favoritos.svg'


export default class HowToJoin extends Component {

    iconStyle = {
        width: '3em',
        height: '3em'
    }

    render() {
        return (
            <div className="container pt-5 fs-5">
                <div className="row">
                    <div className="col">
                        <strong>
                        <p className="display-3">
                            ¿Cómo funciona la contabilidad en línea con Grupo Zwolf?
                        </p>
                        </strong>
                    </div>
                </div>
                <div className="row ">
                    <div className="card-group pt-5">
                        <div className="card delete-border-from-card">
                            <img className="card-img-top mx-auto icon-color-blue" src={ number1 } alt="factura" style={this.iconStyle} />
                            <div className="card-body">
                                <h5 className="card-title text-uppercase p-4">Regístrate</h5>
                                <p className="card-text text-muted">Regístrate en nuestro sistema con un correo de gmail de preferencia y obtén tu alta como cliente.</p>
                                <p className="card-text text-muted">Un asesor se pondrá en contacto contigo para verificar tu identidad y resolver cualquier duda pendiente antes de activar tu cuenta.</p>
                            </div>
                        </div>
                        <div className="card delete-border-from-card">
                            <img className="card-img-top mx-auto icon-color-blue" src={ number2 } alt="Contabilidad" style={ this.iconStyle }/>
                            <div className="card-body">
                                <h5 className="card-title text-uppercase p-4">Activa tu cuenta</h5>
                                <p className="card-text text-muted">Se crearán carpetas en la nube y te llegará un enlace en donde podrás cargar toda tu documentación la cual estará sincronizada entre nuestro equipo de asesores y tú</p>
                                <p className="card-text text-muted">¿No tienes completa tu documentación? No te preocupes, nosotros te ayudamos, ponte en contacto.</p>
                            </div>
                        </div>
                        <div className="card delete-border-from-card">
                            <img className="card-img-top mx-auto icon-color-blue" src={ number3 } alt="Declaraciones" style={ this.iconStyle }/>
                            <div className="card-body">
                                <h5 className="card-title text-uppercase p-4">¡Felicidades!</h5>
                                <p className="card-text text-muted">Bienvenido a Grupo Zwolf.</p>
                                <p className="card-text text-muted">Te regalamos 20 folios de Facturación electrónica en nuestra plataforma para que puedas dar un servicio integral a tus clientes.</p>
                                <p className="card-text text-muted">Carga tu estado de cuenta mensualmente dentro de la carpeta "Estado de cuenta" en la nube para que nuestro personal comience a trabajar.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                    <div className="card-group pt-5">
                        <div className="card delete-border-from-card mx-auto">
                                <img className="card-img-top mx-auto icon-color-blue" src={ star } alt="Declaraciones" style={ this.iconStyle }/>
                                <div className="card-body">
                                    <h5 className="card-title text-uppercase p-4">Obtén tu Contabilidad en línea</h5>
                                    <p className="card-text">Paga tu contabilidad en línea hasta que se haya presentado y tengas tus acuses para pago de impuestos</p>
                                    <p className="card-text">No desembolsas dinero hasta que quedes satisfecho y veas resultados. Elige la opcion que prefieras para hacer el pago de tu contabilidad: En línea con tarjeta de crédito o débito, Bancomer (Ventanilla o practicaja), transferencia electrónica, tienda OXXO o 7-Eleven (hay más de 14,000 ubicaciones disponibles en México).</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
