import React, { useRef, useEffect }  from 'react'
import image from './../../../img/thought-catalog-505eectW54k-unsplash.jpg'
import light from './../../../img/icons/lightbulb.svg'
import chess from './../../../img/icons/strategy.svg'
import CountUp from 'react-countup'


export const BlueBreaker = () => {

    const DivRefScroll = useRef();
    const countUpRefScroll = useRef();
    const countUpRefScroll1 = useRef();
    const countUpRefScroll2 = useRef();
    const countUpRefScroll3 = useRef();


    const style = {
        height: '20vw',
        overflow: 'hidden'
    }

    useEffect(() => {
        const handleScroll = (e) => {
            const counter = DivRefScroll.current
            const { y } = counter.getBoundingClientRect();
            if (y <= 800) {
                countUpRefScroll.current.start(1800)
                countUpRefScroll1.current.start(0)
                countUpRefScroll2.current.start(10)
                countUpRefScroll3.current.start(500)
            } else {
                
            }
        }
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    return (
        <>
            <div className="bg-blue-color p-5 shadow">
                <div className="flex-row">
                    <div className="card-group pt-2">
                        <div className="card bg-transparent delete-border-from-card">                            
                            <div className="card-body">
                            <strong><h2 className="card-title text-uppercase py-2 display-3 text-bold">
                            <div ref={ DivRefScroll }  >
                            <CountUp ref={ countUpRefScroll } end={2006} delay={1}>
                            {({ countUpRef }) => (
                                <div>
                                    <span ref={countUpRef} />
                                </div>
                            )}
                            </CountUp>
                            </div>
                            </h2>
                            <h4 className="card-text display-5">Asesorando desde</h4></strong>
                            </div>
                        </div>
                        <div className="card bg-transparent delete-border-from-card">
                            <div className="card-body">
                            <strong><h5 className="card-title text-uppercase py-2 display-3 text-bold">
                                
                                <CountUp ref={ countUpRefScroll1 } end={1} delay={1}>
                                {({ countUpRef }) => (
                                    <div>
                                        <span ref={countUpRef} />
                                    </div>
                                )}
                                </CountUp>
                                
                            </h5>
                                <h4 className="card-text display-5">Presencia en todo el país</h4></strong>
                            </div>
                        </div>
                        <div className="card bg-transparent delete-border-from-card">
                            <div className="card-body">
                                <h5 className="card-title text-uppercase py-2 display-3 text-bold">
                                <CountUp ref={ countUpRefScroll2 } end={32} delay={1}>
                                    {({ countUpRef }) => (
                                        <div>
                                            <span ref={countUpRef} />
                                        </div>
                                    )}
                                    </CountUp>
                                </h5>
                                <h4 className="card-text display-5">Estados de la República</h4>
                            </div>
                        </div>
                        <div className="card bg-transparent delete-border-from-card">
                            <div className="card-body">
                                <h5 className="card-title text-uppercase py-2 display-3 text-bold">
                                <CountUp ref={ countUpRefScroll3 } end={1000} delay={1}>
                                    {({ countUpRef }) => (
                                        <div>
                                            +<span ref={countUpRef} />
                                        </div>
                                    )}
                                    </CountUp>
                                </h5>
                                <h4 className="card-text display-5">Clientes satisfechos</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-sm-none card bg-dark text-white delete-border-from-card">
                <img className="card-img image-oscure" src={ image } alt="Card"  />
                <div className="card-img-overlay animate__animated animate__fadeIn">
                    <div className="row h-100">
                        <div className="col-sm-12 my-auto">
                            <p className="card-title h1 uppercase">Tu despacho contable en línea</p>
                            <p className="card-text">Declaraciones, Proyecciones, Análisis y Asesoría, todo en un solo lugar.. </p>
                        </div>
                    </div>
                </div>
            </div>


            <div className="d-none d-md-block card bg-dark text-white delete-border-from-card" style={ style }>
                <img className="card-img image-oscure" src={ image } alt="Card"  />
                <div className="card-img-overlay animate__animated animate__fadeIn">
                        <div className="row h-100">
                            <div className="col-sm-12 my-auto">
                                <h5 className="card-title display-1 ">Tu despacho contable en línea</h5>
                                <p className="card-text h4">Declaraciones, Proyecciones, Análisis y Asesoría, todo en un solo lugar... </p>
                            </div>
                    </div>
                </div>
            </div>
            <div className="container fs-5">
                <div className="row ">
                    <div className="card-group pt-5 mx-auto">
                        <div className="card delete-border-from-card">
                        <img className="card-img-top mx-auto iconStyle icon-color-blue" src={ light } alt="factura"  />  
                            <div className="card-body">
                                <h5 className="card-title text-uppercase p-4">Personal Capacitado</h5>
                                <p className="card-text text-muted">Todos los miembros de nuestro equipo se actualizan constantemente y son capaces de atender las necesidades contables actuales.</p>
                            </div>
                        </div>
                        <div className="card delete-border-from-card">
                        <img className="card-img-top mx-auto iconStyle icon-color-blue" src={ chess } alt="factura"  />
                            <div className="card-body">
                                <h5 className="card-title text-uppercase p-4">Equipo Diversificado</h5>
                                <p className="card-text text-muted">Nuestro equipo cuenta con diversas licenciaturas, especialidades, maestría y doctorado para mantener tu tranqulidad.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            </>
    )
}


// export default class BlueBreaker extends Component {
    
//     style = {
//         height: '20vw',
//         overflow: 'hidden'
//     }

//     render() {
//         return (
//             <>
//             <div className="bg-blue-color p-5 shadow">
//                 <div className="flex-row">
//                     <div className="card-group pt-2">
//                         <div className="card bg-transparent delete-border-from-card">                            
//                             <div className="card-body">
//                             <strong><h2 className="card-title text-uppercase py-2 display-3 text-bold">2006</h2>
//                             <h4 className="card-text display-5">Asesorando desde</h4></strong>
//                             </div>
//                         </div>
//                         <div className="card bg-transparent delete-border-from-card">
//                             <div className="card-body">
//                             <strong><h5 className="card-title text-uppercase py-2 display-3 text-bold">1</h5>
//                                 <h4 className="card-text display-5">Precencia en todo el país</h4></strong>
//                             </div>
//                         </div>
//                         <div className="card bg-transparent delete-border-from-card">
//                             <div className="card-body">
//                                 <h5 className="card-title text-uppercase py-2 display-3 text-bold">32</h5>
//                                 <h4 className="card-text display-5">Estados de la República</h4>
//                             </div>
//                         </div>
//                         <div className="card bg-transparent delete-border-from-card">
//                             <div className="card-body">
//                                 <h5 className="card-title text-uppercase py-2 display-3 text-bold">+1000</h5>
//                                 <h4 className="card-text display-5">Clientes satisfechos</h4>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <div className="d-sm-none card bg-dark text-white delete-border-from-card">
//                 <img className="card-img image-oscure" src={ image } alt="Card"  />
//                 <div className="card-img-overlay animate__animated animate__fadeIn">
//                     <div className="row h-100">
//                         <div className="col-sm-12 my-auto">
//                             <h5 className="card-title h1 uppercase">Tu despacho contable en línea</h5>
//                             <p className="card-text">Declaraciones, Proyecciones, Análisis y Asesoría, todo en un solo lugar.. </p>
//                         </div>
//                     </div>
//                 </div>
//             </div>


//             <div className="d-none d-md-block card bg-dark text-white delete-border-from-card" style={this.style}>
//                 <img className="card-img image-oscure" src={ image } alt="Card"  />
//                 <div className="card-img-overlay animate__animated animate__fadeIn">
//                         <div className="row h-100">
//                             <div className="col-sm-12 my-auto">
//                                 <h5 className="card-title display-3 ">Tu despacho contable en línea</h5>
//                                 <p className="card-text h5">Declaraciones, Proyecciones, Análisis y Asesoría, todo en un solo lugar... </p>
//                             </div>
//                     </div>
//                 </div>
//             </div>
//             <div className="container">
//                 <div className="row ">
//                     <div className="card-group pt-5 mx-auto">
//                         <div className="card delete-border-from-card">
//                         <img className="card-img-top mx-auto iconStyle" src={ light } alt="factura"  />  
//                             <div className="card-body">
//                                 <h5 className="card-title text-uppercase p-4">Personal Capacitado</h5>
//                                 <p className="card-text text-muted">Todos los miembros de nuestro equipo se actualizan constantemente y son capaces de atender las necesidades contables actuales.</p>
//                             </div>
//                         </div>
//                         <div className="card delete-border-from-card">
//                         <img className="card-img-top mx-auto iconStyle" src={ chess } alt="factura"  />
//                             <div className="card-body">
//                                 <h5 className="card-title text-uppercase p-4">Equipo Diversificado</h5>
//                                 <p className="card-text text-muted">Nuestro equipo cuenta con diversas licenciaturas, especialidades, maestria y doctorado para mantener tu tranqulidad.</p>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
            
//             </>
//         )
//     }
// }
