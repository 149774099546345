import React from 'react'
import image from './../../img/annie-spratt-dWYU3i-mqEo-unsplash.jpg'
import mission from './../../img/icons/target.svg'
import vision from './../../img/icons/binoculars.svg'

export const HowWeAre = () => {

    const iconStyle = {
        width: '5em',
        height: '5em'
    }

    const style = {
        height: '25vw',
        overflow: 'hidden'
    }



    return (
        <>
            <div className="d-sm-none card bg-dark text-white ">
                <img className="card-img image-oscure h-100" src={ image } alt="Card"  />
                <div className="card-img-overlay animate__animated animate__fadeIn">
                    <div className="row h-100">
                        <div className="col-sm-12 my-auto">
                            <h5 className="card-title h1 ">¿Quienes somos?</h5>
                            <p className="card-text">Nuestro objetivo es adaptarnos a tus necesidades para brindarte el mejor servicio contable</p>
                        </div>
                    </div>
                </div>
            </div>


            <div className="d-none d-md-block card bg-dark text-white" style={ style }>
                <img className="card-img image-oscure" src={ image } alt="Card"  />
                <div className="card-img-overlay animate__animated animate__fadeIn">
                        <div className="row h-100">
                            <div className="col-sm-12 my-auto">
                                <h5 className="card-title display-1 ">¿Quienes somos?</h5>
                                <p className="card-text h3">Nuestro objetivo es adaptarnos a tus necesidades para brindarte el mejor servicio contable</p>
                            </div>
                    </div>
                </div>
            </div>

            <div className="container px-5 fs-5">
                <div className="row px-5">
                <div className="card-group py-5">
                        
                        <div className="card delete-border-from-card">
                            <img className="card-img-top mx-auto icon-color-blue" src={ mission } alt="Mision" style={ iconStyle }/>
                            <div className="card-body">
                                <h5 className="card-title text-uppercase p-4">Mision</h5>
                                <p className="card-text text-muted">Asesorar a otras empresas en el mejoramiento de la gestión y sus prácticas empresariales.</p>
                            </div>
                        </div>
                        <div className="card delete-border-from-card">
                            <img className="card-img-top mx-auto icon-color-blue" src={ vision } alt="Visión" style={ iconStyle }/>
                            <div className="card-body">
                                <h5 className="card-title text-uppercase p-4">Vision</h5>
                                <p className="card-text text-muted">Ser una empresa de alta calidad para brindar a todos nuestros clientes la solución que ellos necesitan.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
