import React, { Component } from 'react'

export default class WhatsApp extends Component {
    whatsappLink = "https://api.whatsapp.com/send?phone="+process.env.REACT_APP_CONTACTPHONE+"&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20%20."
    render() {
        return (
            <>
            <a href={ this.whatsappLink } className="float" target="_blank" rel="noreferrer">
            <i className="fa fa-whatsapp my-float"></i>
            </a>
            </>
        )
    }
}
