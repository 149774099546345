import React, {useState} from 'react'
import ReactDOMServer from 'react-dom/server';
import ReCAPTCHA from 'react-google-recaptcha'
import {useForm} from 'react-hook-form'
import ContactEmail from "../../../email/contactEmail";

export default function ContactForm() {

    const [state, setState] = useState({
        isDisable: true,
        isSended: false
    })

    const {register, handleSubmit, formState: {errors}} = useForm();


    const onChange = (value) => {
        if (!state.isSended) {
            if (value) {
                setState({isDisable: false, isSended: state.isSended});
            } else {
                setState({isDisable: true, isSended: state.isSended});
            }
        }
    }

    const onSubmit = async (data) => {
        setState({isDisable: state.isDisable, isSended: true});

        const htmlMessage = ReactDOMServer.renderToStaticMarkup(
            <ContactEmail
                name={data.name}
                email={data.email}
                subject={data.asunto}
                message={data.message}
            />
        );
        data.to = "info@despachozwolf.com";
        data.subject = "Contacto desde la página web";
        data.text = htmlMessage;



        const response = await fetch('https://despachozwolf.com/api/send-email.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        if (response.ok) {// Esto convierte la respuesta a JSON
            const data = await response.json();
            console.log(data);
        }

    }


    return (
        <div className="container fs-5">
            <div className="row">
                <div className="col text-left pt-5">
                    <h5 className="display-1 uppercase">
                        Contáctanos
                    </h5>
                    <p className="h5">
                        Mándanos un correo y un asesor contable te contactará
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col p-5">
                    <form onSubmit={handleSubmit(onSubmit)} className="needs-validation" noValidate>
                        <div className="mb-3 text-start">
                            <label htmlFor="name" className="form-label">Nombre</label>
                            <input type="text" className="form-control"
                                   id="name" {...register("name", {required: true,})} />
                            <div className="invalid-feedback">
                            </div>
                        </div>
                        <div className="mb-3 text-start">
                            <label htmlFor="exampleInputPassword1" className="form-label">Correo</label>
                            <input type="email" className="form-control"
                                   id="exampleInputPassword1" {...register("email", {required: true,})} />
                        </div>
                        <div className="mb-3 text-start">
                            <label htmlFor="asunto" className="form-label">Asunto</label>
                            <input type="text" className="form-control"
                                   id="asunto" {...register("asunto", {required: true,})} />
                        </div>
                        <div className="mb-3 text-start">
                            <label htmlFor="message" className="form-label">Mensaje</label>
                            <input type="text" className="form-control"
                                   id="message" {...register("message", {required: true,})} />
                        </div>
                        <div className="col">
                            <ReCAPTCHA
                                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                onChange={onChange}
                            ></ReCAPTCHA>
                        </div>
                        <div className="col">
                            {
                                !state.isSended && <button type="submit" className="btn btn-primary btn-lg"
                                                           disabled={state.isDisable}>Envíar</button>
                            }
                            {
                                state.isSended &&
                                <p className="text-success animate__animated animate__animate_fadeIn">Enviado,
                                    espere respuesta de nuestro equipo</p>
                            }
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}


