import React, {Component, useEffect, useState} from 'react'
import {Helmet} from 'react-helmet'
import image from '../../../img/scott-graham-5fNmWej4tAA-unsplash.webp'
import './WelcomeBanner.css'

const WelcomeBanner = () => {

    let style = {
        height: '43vw',
        overflow: 'hidden'
    }


    return (
        <>
            <div className="d-sm-none card bg-dark text-white animate__animated animate__fadeIn">


                <Helmet>
                    <link rel="preload" href={image} as="image"/>
                </Helmet>
                <img className="card-img image-oscure" height='200' src={image} alt="Card"/>
                <div className="card-img-overlay animate__animated animate__fadeIn">
                    <div className="row h-100">
                        <div className="col-sm-12 my-auto">
                            <strong><h5 className="card-title h1 ">Contabilidad en línea</h5></strong>
                            <p className="card-text ">La seguridad de contar con expertos</p>
                            <a href="https://sistema.grupozwolf.com/registro">
                                <button type="button" className="btn btn-info text-white btn-lg"> Regístrate
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>


            <div className="d-none d-md-block card bg-dark text-white animate__animated animate__fadeIn"
                 style={style}>

                <img className="card-img image-oscure" src="img/scott-graham-5fNmWej4tAA-unsplash.webp"
                     width="200"
                     height="700" alt="Card"/>
                <div className="card-img-overlay animate__animated animate__fadeIn">
                    <div className="row h-100">
                        <div className="col-sm-12 my-auto">
                            <strong><p className="card-title display-1 ">Contabilidad en línea</p></strong>
                            <p className="card-text h4">La seguridad de contar con expertos</p>
                            <a href="https://sistema.grupozwolf.com/registro">
                                <button type="button" className="btn btn-info text-white btn-lg"> Regístrate
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default WelcomeBanner
