import React from 'react'
import { 
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch

} from 'react-router-dom'
import { FAQScreen } from '../components/mainScreen/FAQScreen'
import { HomeScreen } from '../components/mainScreen/HomeScreen'
import { HowWeAre } from '../components/mainScreen/HowWeAre'
import { PoliticaScreen } from '../components/mainScreen/PoliticaScreen'
// import { PreciosScreen } from '../components/mainScreen/PreciosScreen'
import { PrivacidadScreen } from '../components/mainScreen/PrivacidadScreen'

export const AppRouter = () => {
    return (
        <>
        <Router>
            <Switch>
                <Route exact path="/home" component={ HomeScreen } ></Route>
                <Route exact path="/quienes-somos" component = { HowWeAre }></Route>
                {/*<Route exact path="/precios-contabilidad" component = { PreciosScreen }></Route>*/}
                <Route exact path="/preguntas-frecuentes" component = { FAQScreen }></Route>
                {/*<Route exact path="/politicas-para-franquicias" component = { PoliticaScreen }></Route>*/}
                <Route exact path="/aviso-de-privacidad" component = { PrivacidadScreen }></Route>
                <Redirect to="/home"></Redirect>
            </Switch>
        </Router>
            
        </>
    )
}
